
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93O5ofFNL4m4Meta } from "/app/packages/shop/pages/[...slug].vue?macro=true";
import { default as _91product_93wOJlIYPzTkMeta } from "/app/packages/shop/pages/product/[productType]/[product].vue?macro=true";
import { default as searchErnJx0MT89Meta } from "/app/packages/shop/pages/search.vue?macro=true";
import { default as _91shopSlug_93wV9lVQaNelMeta } from "/app/packages/shop/pages/shop/[shopSlug].vue?macro=true";
import { default as wishlistJul8bWHV2vMeta } from "/app/packages/shop/pages/wishlist.vue?macro=true";
import { default as component_45stubW0rpTDMr99Meta } from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.1_@types+node@20.17.25_db0@0.3.1_encoding@0.1.13_eslint@8.57._mzjkw4etouepfajvkok7p7suf4/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubW0rpTDMr99 } from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.1_@types+node@20.17.25_db0@0.3.1_encoding@0.1.13_eslint@8.57._mzjkw4etouepfajvkok7p7suf4/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___de",
    path: "/de/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue")
  },
  {
    name: "slug___de-at",
    path: "/de-at/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue")
  },
  {
    name: "slug___de-ch",
    path: "/de-ch/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue")
  },
  {
    name: "slug___de-it",
    path: "/de-it/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue")
  },
  {
    name: "slug___de-lu",
    path: "/de-lu/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue")
  },
  {
    name: "slug___de-li",
    path: "/de-li/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue")
  },
  {
    name: "slug___nl",
    path: "/nl/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue")
  },
  {
    name: "slug___nl-be",
    path: "/nl-be/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue")
  },
  {
    name: "slug___fr",
    path: "/fr/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue")
  },
  {
    name: "slug___fr-be",
    path: "/fr-be/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue")
  },
  {
    name: "slug___fr-ch",
    path: "/fr-ch/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue")
  },
  {
    name: "slug___fr-lu",
    path: "/fr-lu/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue")
  },
  {
    name: "slug___uk",
    path: "/uk/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue")
  },
  {
    name: "slug___it",
    path: "/it/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue")
  },
  {
    name: "slug___it-ch",
    path: "/it-ch/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue")
  },
  {
    name: "slug___es",
    path: "/es/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue")
  },
  {
    name: "product-productType-product___de",
    path: "/de/produkt/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue")
  },
  {
    name: "product-productType-product___de-at",
    path: "/de-at/produkt/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue")
  },
  {
    name: "product-productType-product___de-ch",
    path: "/de-ch/produkt/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue")
  },
  {
    name: "product-productType-product___de-it",
    path: "/de-it/produkt/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue")
  },
  {
    name: "product-productType-product___de-lu",
    path: "/de-lu/produkt/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue")
  },
  {
    name: "product-productType-product___de-li",
    path: "/de-li/produkt/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue")
  },
  {
    name: "product-productType-product___nl",
    path: "/nl/product/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue")
  },
  {
    name: "product-productType-product___nl-be",
    path: "/nl-be/product/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue")
  },
  {
    name: "product-productType-product___fr",
    path: "/fr/produit/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue")
  },
  {
    name: "product-productType-product___fr-be",
    path: "/fr-be/produit/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue")
  },
  {
    name: "product-productType-product___fr-ch",
    path: "/fr-ch/produit/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue")
  },
  {
    name: "product-productType-product___fr-lu",
    path: "/fr-lu/produit/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue")
  },
  {
    name: "product-productType-product___uk",
    path: "/uk/product/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue")
  },
  {
    name: "product-productType-product___it",
    path: "/it/prodotto/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue")
  },
  {
    name: "product-productType-product___it-ch",
    path: "/it-ch/prodotto/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue")
  },
  {
    name: "product-productType-product___es",
    path: "/es/producto/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue")
  },
  {
    name: "search___de",
    path: "/de/search",
    component: () => import("/app/packages/shop/pages/search.vue")
  },
  {
    name: "search___de-at",
    path: "/de-at/search",
    component: () => import("/app/packages/shop/pages/search.vue")
  },
  {
    name: "search___de-ch",
    path: "/de-ch/search",
    component: () => import("/app/packages/shop/pages/search.vue")
  },
  {
    name: "search___de-it",
    path: "/de-it/search",
    component: () => import("/app/packages/shop/pages/search.vue")
  },
  {
    name: "search___de-lu",
    path: "/de-lu/search",
    component: () => import("/app/packages/shop/pages/search.vue")
  },
  {
    name: "search___de-li",
    path: "/de-li/search",
    component: () => import("/app/packages/shop/pages/search.vue")
  },
  {
    name: "search___nl",
    path: "/nl/search",
    component: () => import("/app/packages/shop/pages/search.vue")
  },
  {
    name: "search___nl-be",
    path: "/nl-be/search",
    component: () => import("/app/packages/shop/pages/search.vue")
  },
  {
    name: "search___fr",
    path: "/fr/search",
    component: () => import("/app/packages/shop/pages/search.vue")
  },
  {
    name: "search___fr-be",
    path: "/fr-be/search",
    component: () => import("/app/packages/shop/pages/search.vue")
  },
  {
    name: "search___fr-ch",
    path: "/fr-ch/search",
    component: () => import("/app/packages/shop/pages/search.vue")
  },
  {
    name: "search___fr-lu",
    path: "/fr-lu/search",
    component: () => import("/app/packages/shop/pages/search.vue")
  },
  {
    name: "search___uk",
    path: "/uk/search",
    component: () => import("/app/packages/shop/pages/search.vue")
  },
  {
    name: "search___it",
    path: "/it/search",
    component: () => import("/app/packages/shop/pages/search.vue")
  },
  {
    name: "search___it-ch",
    path: "/it-ch/search",
    component: () => import("/app/packages/shop/pages/search.vue")
  },
  {
    name: "search___es",
    path: "/es/search",
    component: () => import("/app/packages/shop/pages/search.vue")
  },
  {
    name: "shop-shopSlug___de",
    path: "/de/shop/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue")
  },
  {
    name: "shop-shopSlug___de-at",
    path: "/de-at/shop/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue")
  },
  {
    name: "shop-shopSlug___de-ch",
    path: "/de-ch/shop/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue")
  },
  {
    name: "shop-shopSlug___de-it",
    path: "/de-it/shop/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue")
  },
  {
    name: "shop-shopSlug___de-lu",
    path: "/de-lu/shop/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue")
  },
  {
    name: "shop-shopSlug___de-li",
    path: "/de-li/shop/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue")
  },
  {
    name: "shop-shopSlug___nl",
    path: "/nl/winkel/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue")
  },
  {
    name: "shop-shopSlug___nl-be",
    path: "/nl-be/winkel/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue")
  },
  {
    name: "shop-shopSlug___fr",
    path: "/fr/boutique/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue")
  },
  {
    name: "shop-shopSlug___fr-be",
    path: "/fr-be/boutique/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue")
  },
  {
    name: "shop-shopSlug___fr-ch",
    path: "/fr-ch/boutique/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue")
  },
  {
    name: "shop-shopSlug___fr-lu",
    path: "/fr-lu/boutique/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue")
  },
  {
    name: "shop-shopSlug___uk",
    path: "/uk/shop/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue")
  },
  {
    name: "shop-shopSlug___it",
    path: "/it/negozio/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue")
  },
  {
    name: "shop-shopSlug___it-ch",
    path: "/it-ch/negozio/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue")
  },
  {
    name: "shop-shopSlug___es",
    path: "/es/tienda/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue")
  },
  {
    name: "wishlist___de",
    path: "/de/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue")
  },
  {
    name: "wishlist___de-at",
    path: "/de-at/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue")
  },
  {
    name: "wishlist___de-ch",
    path: "/de-ch/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue")
  },
  {
    name: "wishlist___de-it",
    path: "/de-it/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue")
  },
  {
    name: "wishlist___de-lu",
    path: "/de-lu/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue")
  },
  {
    name: "wishlist___de-li",
    path: "/de-li/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue")
  },
  {
    name: "wishlist___nl",
    path: "/nl/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue")
  },
  {
    name: "wishlist___nl-be",
    path: "/nl-be/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue")
  },
  {
    name: "wishlist___fr",
    path: "/fr/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue")
  },
  {
    name: "wishlist___fr-be",
    path: "/fr-be/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue")
  },
  {
    name: "wishlist___fr-ch",
    path: "/fr-ch/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue")
  },
  {
    name: "wishlist___fr-lu",
    path: "/fr-lu/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue")
  },
  {
    name: "wishlist___uk",
    path: "/uk/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue")
  },
  {
    name: "wishlist___it",
    path: "/it/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue")
  },
  {
    name: "wishlist___it-ch",
    path: "/it-ch/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue")
  },
  {
    name: "wishlist___es",
    path: "/es/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue")
  },
  {
    name: component_45stubW0rpTDMr99Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubW0rpTDMr99
  }
]