import revive_payload_client_GPtVUj0oTu from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.1_@types+node@20.17.25_db0@0.3.1_encoding@0.1.13_eslint@8.57._mzjkw4etouepfajvkok7p7suf4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_HWltdIVCs2 from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.1_@types+node@20.17.25_db0@0.3.1_encoding@0.1.13_eslint@8.57._mzjkw4etouepfajvkok7p7suf4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CqtWllZwxi from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.1_@types+node@20.17.25_db0@0.3.1_encoding@0.1.13_eslint@8.57._mzjkw4etouepfajvkok7p7suf4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7q4Hxserxx from "/app/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.7.3_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_Ypo8SrvogO from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.1_@types+node@20.17.25_db0@0.3.1_encoding@0.1.13_eslint@8.57._mzjkw4etouepfajvkok7p7suf4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_lJdy6rMAlV from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.1_@types+node@20.17.25_db0@0.3.1_encoding@0.1.13_eslint@8.57._mzjkw4etouepfajvkok7p7suf4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_dER6NZhpyu from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.1_@types+node@20.17.25_db0@0.3.1_encoding@0.1.13_eslint@8.57._mzjkw4etouepfajvkok7p7suf4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_06j0wBVqMm from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.1_@types+node@20.17.25_db0@0.3.1_encoding@0.1.13_eslint@8.57._mzjkw4etouepfajvkok7p7suf4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/packages/shop/.nuxt/components.plugin.mjs";
import prefetch_client_1u9Whags86 from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.1_@types+node@20.17.25_db0@0.3.1_encoding@0.1.13_eslint@8.57._mzjkw4etouepfajvkok7p7suf4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_dHcKRsr7Ek from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.2.1_@vue+compiler-dom@3.5.13_eslint@8.57.0_magicast@0.3.5_rollup@4.36.0_typesc_pujy2k22mmnbtpgldwpg2uc6sa/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_SNNlEz06bW from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.2.1_@vue+compiler-dom@3.5.13_eslint@8.57.0_magicast@0.3.5_rollup@4.36.0_typesc_pujy2k22mmnbtpgldwpg2uc6sa/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_To5HoKB206 from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.2.1_@vue+compiler-dom@3.5.13_eslint@8.57.0_magicast@0.3.5_rollup@4.36.0_typesc_pujy2k22mmnbtpgldwpg2uc6sa/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_OHU0vggoM9 from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_N3oxAqIu47 from "/app/node_modules/.pnpm/@nuxt+image@1.3.0_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5_rollup@4.36.0/node_modules/@nuxt/image/dist/runtime/plugin.mjs";
import nuxt_plugin_v9eNfebnjW from "/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.36.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_t4cRrbQqIQ from "/app/node_modules/.pnpm/nuxt-graphql-client@0.2.36_@parcel+watcher@2.5.1_@types+node@20.17.25_encoding@0.1.13_enquire_iogxoay55szcv2ckqqcn6kw6wa/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import plugin_pXsX5HN9cw from "/app/node_modules/.pnpm/@storyblok+nuxt@6.2.4_vue@3.5.13_typescript@5.7.3_/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import i18n_Gn7fnS6kt3 from "/app/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.7.3_/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import gtm_client_u5ie5M7H6O from "/app/packages/shared-components/plugins/gtm.client.ts";
import usercentrics_wjVkGbQfmn from "/app/packages/shared-components/plugins/usercentrics.ts";
import datadog_client_cfAAU11B0P from "/app/packages/shop/plugins/datadog.client.ts";
import gql_3PotKuelAp from "/app/packages/shop/plugins/gql.ts";
import referer_9No3FWTUTl from "/app/packages/shop/plugins/referer.ts";
import split_MLtUoUIS9d from "/app/packages/shop/plugins/split.ts";
import vue_query_wrmMkNpEpe from "/app/packages/shop/plugins/vue-query.ts";
import ssg_detect_ucDL60vyJA from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.2.1_@vue+compiler-dom@3.5.13_eslint@8.57.0_magicast@0.3.5_rollup@4.36.0_typesc_pujy2k22mmnbtpgldwpg2uc6sa/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_GPtVUj0oTu,
  unhead_HWltdIVCs2,
  router_CqtWllZwxi,
  _0_siteConfig_7q4Hxserxx,
  payload_client_Ypo8SrvogO,
  navigation_repaint_client_lJdy6rMAlV,
  check_outdated_build_client_dER6NZhpyu,
  chunk_reload_client_06j0wBVqMm,
  components_plugin_KR1HBZs4kY,
  prefetch_client_1u9Whags86,
  switch_locale_path_ssr_dHcKRsr7Ek,
  route_locale_detect_SNNlEz06bW,
  i18n_To5HoKB206,
  plugin_OHU0vggoM9,
  plugin_N3oxAqIu47,
  nuxt_plugin_v9eNfebnjW,
  plugin_t4cRrbQqIQ,
  plugin_pXsX5HN9cw,
  i18n_Gn7fnS6kt3,
  gtm_client_u5ie5M7H6O,
  usercentrics_wjVkGbQfmn,
  datadog_client_cfAAU11B0P,
  gql_3PotKuelAp,
  referer_9No3FWTUTl,
  split_MLtUoUIS9d,
  vue_query_wrmMkNpEpe,
  ssg_detect_ucDL60vyJA
]